export interface DataPickerDialogFilter {
  initialDate: string;
  finalDate: string;
};

export interface OsInfo {
  device: string;
  platform: string;
  mobi_version: string;
  desktop_version?: string;
}
