import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { DAYS_OF_THE_WEEK, DEFAULT_DATE_FORMAT } from '@shared/constants';
import { AVAILABLE_LANGUAGES, BrowsersDevices, OSDevices, UnknownDevice } from '@shared/enums';
import { isLcsPanelPage } from '@shared/utils';
import { ElectronService } from 'ngx-electron';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private notifier: NotifierService,
    private electron: ElectronService,
    private translate: TranslateService,
  ) { }

  /**
   * Parse a date without timezone to one with a specific time zone
   * @param {string} date Date value
   * @param {string} timeZone String that contains the timezone
   * @returns Date in the new timezone
   */
  public getDateInTimeZone(
    date: string | Date,
    timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  ): string {
    if (!date) return '';

    // Parse date
    const currentDate = date instanceof Date ? date.toString() : date;

    return (
      currentDate &&
      new Date(`${currentDate.replace(/-/g, '/')} GMT-0000`).toLocaleString('en', {
        timeZone,
      })
    );
  }

  /**
   * Get the diff between to dates in seconds
   * @param {Date} startDate Start date
   * @param {Date} endDate Final date
   * @returns {number} Diff in seconds
   */
  public getSecondsDiff(startDate: Date, endDate: Date): number {
    const msInSecond = 1000;
    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInSecond
    );
  }

  /**
   * Get day of the week by date
   * @param {Date} date Date
   * @returns {string} Day of the week
   */
  public getDayByDate(date: Date): string {
    return DAYS_OF_THE_WEEK[date.getDay()];
  }

  /**
   * Get Difference Between Arrays
   * @param {T[]} arr1 Fist array
   * @param {T[]} arr2 Second array
   * @returns {T[]} Diff
   */
  public getDiffBetweenArrays<T>(arr1: T[], arr2: T[]): T[] {
    return arr1.filter((x) => !arr2.includes(x));
  }

  /**
   * Get similarities between arrays
   * @param {T[]} arr1 Fist array
   * @param {T[]} arr2 Second array
   */
  public getSimilaritiesBetweenArrays<T>(arr1: T[], arr2: T[]): T[] {
    return arr1.filter((x) => arr2.includes(x));
  }

  /**
   * Copy link
   */
  public copy(text: string): void {
    try {
      // Check if text is empty
      if (!text) throw new Error('Text is empty');

      // Remove empty spaces
      text = text.replace(/&nbsp;/g, ' ').trim();

      // Copy the text
      navigator.clipboard.writeText(text);

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'copyToClipboard',
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (error) {
      // Log error
      console.error(error);

      // Show notification
      this.notifier.showNotification({
        type: 'error',
        message: 'errorCopyText',
        actionText: 'OK',
        panelClass: 'error',
      });
    }
  }
  /**
   * Get date format by language
   * @returns {string} Date format
   */
  public getDateFormatByLocale(fully: boolean = false): string {
    switch (this.translate.currentLang) {
      // Spanish
      case AVAILABLE_LANGUAGES.ES:
        return fully ? 'dd/MM/yyyy HH:mm' : 'dd/MM HH:mm';

      // English - AM and PM
      case AVAILABLE_LANGUAGES.EN:
        return fully ? 'MM/dd/yyyy hh:mm a' : 'MM/dd hh:mm a';

      // Brazilian portuguese
      default:
        return fully ? 'dd/MM/yyyy HH:mm' : 'dd/MM HH:mm';
    }
  }

  /**
   * Get Date Format String
   * @returns {string} Date format
   */
  public getDateFormatString(): string {
    return this.getDateFormatByLocale(true).replace('HH:mm', '').toUpperCase();
  }

  /**
   * Navigate in admin panel
   * @param {string} path Path
   */
  public navigateInAdminPanel(path: string): void {
    // Get current url
    const url = this.router.url;

    // Split
    const splitUrl = url.split('/');

    // Get first position
    const firstPosition = isLcsPanelPage() ? 3 : 2;

    // Navigate
    this.router.navigate([splitUrl.slice(0, firstPosition).join('/') + path]);
  }


  /**
   * Get date locate
   * @returns {string} Date locate
   */
  public getLocale(): string {
    switch (this.translate.currentLang) {
      // English
      case AVAILABLE_LANGUAGES.EN:
        return 'en-US';

      // Spanish
      case AVAILABLE_LANGUAGES.ES:
        return 'es-ES';

      // Brazilian portuguese
      default:
        return 'pt-BR';
    }
  }

  /**
   * Get date format by language
   * @returns {string} Date format
   */
  public getFileDateFormatByLocale(): string {
    return 'dd-MM-yyyy_HH-mm-ss';
  }

  /**
   * Download file
   * @param {string} url File url
   * @param {fileName} fileName File name
   */
  public downloadFile(url: string, fileName: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  /**
   * Get a new Attr value if exist
   * @param {string} attr Attr that will be parse
   * @param {{ key: string, value: string }[]} options All options of parse
   * @returns {string} New attr value
   */
  public parseAttr(
    attr: string,
    options: { key: string; value: string }[]
  ): string {
    return options.find((type) => type.key === attr)?.value ?? attr;
  }

  /**
   * Convert image to base64
   * @param {string} imageUrl URL of the image
   * @returns {Promise<string>} Base64 string
   */
  public async convertImageToBase64(imageUrl: string): Promise<string> {
    try {
      // Get image blob
      const imageBlob = await fetch(imageUrl).then((response) =>
        response.blob()
      );

      // Convert image to base64
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () => resolve(imageUrl);
      });
    } catch (error) {
      console.error('Error - convertImageToBase64:', error);
      return imageUrl;
    }
  }

  normalizePhone(extension: string): string {
    let normalizedPhone = extension.split('_')[0];

    if (normalizedPhone.includes('lcs') && normalizedPhone.includes('-')) {
      normalizedPhone = normalizedPhone.split('-')[1];
    }

    return normalizedPhone;
  }

  /**
  * Remove timezone
  * @param {string} date Date with time zone value
  * @returns Date without timezone
  */
  public getDateWithoutTimeZone(date: string | Date, i18n = false): string {
    if (!date) return null;
    const currentDate = date instanceof Date ? date : new Date(date);
    const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const newDate = new Date(currentDate.getTime() + userTimezoneOffset);
    return this.getFormattedDate(newDate, i18n);
  }

  /**
   * Get formatted date
   * @param {string} date Date value
   * @returns {string} Formatted date
   */
  public getFormattedDate(date: string | Date, i18n = false): string {
    if (!date) return '';
    const currentDate = date instanceof Date ? date : new Date(date);
    return this.datePipe.transform(currentDate, i18n ? this.getDateFormatByLocale(true) : DEFAULT_DATE_FORMAT);
  }

  /**
   * Get date with timezone
   * @param {string | Date} date Date with time zone value
   * @returns Date with timezone
   */
  public getDateI18nWithTimeZone(date: string | Date): string {
    // Check if date is valid
    if (!date) return '';

    // Parse date
    const currentDate = date instanceof Date ? date : new Date(date);

    // Get timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Return date in new format
    return this.datePipe.transform(
      currentDate.toLocaleString('en', { timeZone: timezone }),
      this.getDateFormatByLocale(true)
    );
  }

  /**
   * Get browser name
   * @returns {string} Browser name
   */
  public getBrowserName(): string {
    const agent: string = window.navigator.userAgent;
    switch (true) {
      case /edge/i.test(agent):
        return BrowsersDevices.MICROSOFT_EDGE;
      case /edg/i.test(agent):
        return BrowsersDevices.MICROSOFT_EDGE_CHROMIUM;
      case /opr/i.test(agent):
        return BrowsersDevices.OPERA;
      case /chrome/i.test(agent):
        return BrowsersDevices.GOOGLE_CHROME;
      case /safari/i.test(agent):
        return BrowsersDevices.SAFARI;
      case /firefox/i.test(agent):
        return BrowsersDevices.MOZILLA_FIREFOX;
      case /msie/i.test(agent):
        return BrowsersDevices.INTERNET_EXPLORER;
      case /ucbrowser/i.test(agent):
        return BrowsersDevices.UC_BROWSER;
      case /yabrowser/i.test(agent):
        return BrowsersDevices.YANDEX;
      default:
        return UnknownDevice.UNKNOWN;
    }
  }

  /**
   * Get witch OS is running
   * @returns {string} Operating system name
   */
  public getDeviceName(): string {
    // Check if is electron app
    if (!this.electron.isElectronApp) return this.getBrowserName();

    // Get OS name
    switch (true) {
      case this.electron.isWindows:
        return OSDevices.WINDOWS;
      case this.electron.isLinux:
        return OSDevices.LINUX;
      default:
        return UnknownDevice.UNKNOWN;
    }
  }

  /**
   * Get array of objects without duplicated elements, based on specific field
   * @param arr Primary array
   * @param field Field to be checked as duplicate
   * @returns the new arr
   */
  public getArrWithoutDuplicatedFieds(arr: any[], field: string): Object[] {
    const uniqueSet = new Set();
    const result = [];

    arr.forEach(item => {
      if (!uniqueSet.has(item[field])) {
        uniqueSet.add(item[field]);
        result.push(item);
      }
    });

    return result;
  }
}
