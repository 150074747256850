<div style="height: 100%">

  <div class="advanced-filter-title">
    <h2 mat-dialog-title class="dialog-title"> {{ "filter" | translate }} </h2>
  </div>

  <mat-dialog-content class="mat-typography">
    <form role="form" class="dialog-content" [formGroup]="form">

      <div class="row fx-gap responsive-row" style="margin-bottom: 10px;">

        <app-datepicker class="full-width" label="initialDate" [form]="form" controlName="initialDate">
        </app-datepicker>

        <app-datepicker [minDate]="form.get('initialDate').value" class="full-width" label="finalDate" [form]="form"
          controlName="finalDate">
        </app-datepicker>

      </div>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button *ngIf="data.filterData" mat-button class="dialog-cancel-btn" (click)="cleanFilter()"> {{ "clear" | translate
      }} </button>
    <button mat-button mat-dialog-close class="dialog-cancel-btn"> {{ "cancel" | translate }} </button>
    <button mat-raised-button class="dialog-save-btn" (click)="save()" [disabled]="form.invalid || isFormEmpty()">
      {{ "apply" | translate }}
    </button>
  </mat-dialog-actions>

</div>
