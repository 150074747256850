<div class="actions">
  <mat-icon mat-dialog-close>close</mat-icon>
  <mat-icon *ngIf="canShowDownload()" (click)="download()">download</mat-icon>
  <mat-icon *ngIf="canShowOpen()" (click)="open()">open_in_new</mat-icon>
  <mat-icon *ngIf="canShowZoom()" (click)="zoomIn($event)">zoom_in</mat-icon>
  <mat-icon *ngIf="canShowZoom()" (click)="zoomOut($event)">zoom_out</mat-icon>
</div>

<div [ngSwitch]="data.type">

  <!-- Normal -->
  <div *ngSwitchCase="'normal'">
    <div #imgContainer class="photo" style="overflow: hidden;">
      <img #imgContent (load)="contentLoaded()" (error)="onImgError($event)" [src]="data.url | safeResourceUrl"
        (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)"
        (mouseleave)="onMouseLeave()" style="max-width:80vw; max-height: 80vh; min-width: 150px;" />
    </div>
  </div>

  <!-- Documents -->
  <div *ngSwitchCase="'doc'">
    <div [ngSwitch]="extension" class="doc">
      <!-- PDF view -->
      <div *ngSwitchCase="'pdf'">
        <!-- PDF on browser -->
        <embed *ngIf="!isElectronApp; else pdfOnGoogleView" (load)="contentLoaded()" [src]="data.url | safeResourceUrl"
          type="application/pdf">

        <!-- PDF on google view -->
        <ng-template #pdfOnGoogleView>
          <ngx-doc-viewer [url]="link" [viewer]="'google'" (loaded)="contentLoaded()">
          </ngx-doc-viewer>
        </ng-template>
      </div>

      <!-- Text view -->
      <embed *ngSwitchCase="'txt'" (load)="contentLoaded()" [src]="data.url | safeResourceUrl" type="text/plain">

      <!-- DOC view -->
      <ngx-doc-viewer *ngSwitchDefault [url]="link" [viewer]="viewer" (loaded)="contentLoaded()">
      </ngx-doc-viewer>
    </div>

  </div>

  <!-- Video -->
  <div *ngSwitchCase="'video'">
    <video class="video" controls>
      <source [src]="link" />
      Browser not supported
    </video>
  </div>

</div>

<div *ngIf="isLoading && data.type !== 'video'">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
