import { RoomFileType } from "@shared/enums"
import { RoomFileOption } from "@shared/models"

export const DEFAULT_RING_SETTINGS = {
  src: 'assets/audio/ringing.wav',
  loop: true,
  volume: 0.5,
}

export const DEFAULT_NOTIFICATION_SETTINGS = {
  src: 'assets/audio/notification.ogg',
  loop: false,
  volume: 0.5,
}

export const DEFAULT_CALLING_SETTINGS = {
  src: 'assets/audio/calling.wav',
  loop: true,
  volume: 0.5,
}

export const DEFAULT_BUSY_SETTINGS = {
  src: 'assets/audio/busy.wav',
  loop: true,
  volume: 0.5,
}

export const ROOM_FILE_TYPES: RoomFileOption[] = [
  { label: 'otherType', value: RoomFileType.OTHER, icon: 'file-document' },
  { label: 'imageType', value: RoomFileType.IMAGE, icon: 'file-image' },
  { label: 'audioType', value: RoomFileType.AUDIO, icon: 'file-audio' },
  { label: 'videoType', value: RoomFileType.VIDEO, icon: 'file-video' },
]
