import { environment } from '@environments/environment';
import { ExternalJWTPayload, OsInfo } from '@shared/models';
import {
  getLSStatusConnectionKey,
  getLSTenantId,
  getLSUserEmail,
  getLSUserId,
  getMatrixCredentials
} from '@shared/utils';
import { KJUR, b64utoutf8 } from 'jsrsasign';

// Constants
const ALG = 'HS256';
const TYPE = 'JWT';
const HEADER = { alg: ALG, typ: TYPE };
const SECRET = 'fsexvmkleeuawdwl'

/**
 * Create JWT Token
 * @param {string} roomId Room Id
 * @param {string} photoUrl Photo Url
 * @param {string} displayName User name
 * @param {string} accessToken Matrix Access token
 * @param {string} matrixServerName Server name
 * @returns {string} JWT
 */
export function createMatrixJWTToken(
  roomId: string,
  photoUrl: string,
  displayName: string,
  accessToken: string,
  matrixServerName: string,
  OSInfo: OsInfo,
  subject: string,
  members?: string[]
): string {
  // Domain
  const domain = environment.jitsiDomain;

  // Payload
  const payload = {
    // As per Jitsi token auth, `iss` needs to be set to something agreed between
    // JWT generating side and Prosody config. Since we have no configuration for
    // the widgets, we can't set one anywhere. Using the Jitsi domain here probably makes sense.
    iss: domain,
    sub: domain,
    aud: `https://${domain}`,
    room: "*",
    context: {
      matrix: {
        token: accessToken,
        room_id: roomId,
        server_name: matrixServerName,
      },
      user: {
        avatar: photoUrl,
        name: displayName,
        companyId: getLSTenantId(),
        email: getLSUserEmail(),
        userId: getLSUserId(),
        connection: getLSStatusConnectionKey(),
        matrix: getMatrixCredentials(),
        os: OSInfo,
        subject,
        members
      },
    },
  };
  // Sign JWT
  // The secret string here is irrelevant, we're only using the JWT
  // to transport data to Prosody in the Jitsi stack.
  return KJUR.jws.JWS.sign(
    ALG,
    JSON.stringify(HEADER),
    JSON.stringify(payload),
    'notused',
  );
}

/**
 * Create External JWT Token
 * @param {string} data Payload
 * @returns {string} JWT Token
 */
export function createExternalJWTToken<T>(data: T): string {
  // Get initial date
  const current = KJUR.jws.IntDate.get('now');

  // Expiration
  const expiration = current + 3600; // 3600 sec

  // Payload
  const payload: ExternalJWTPayload<T> = {
    iat: current,
    nbf: current,
    exp: expiration,
    data,
  };

  // Sign JWT
  return KJUR.jws.JWS.sign(
    ALG,
    JSON.stringify(HEADER),
    JSON.stringify(payload),
    SECRET
  );
}

/**
 * Verify External JWT Token
 * @param jwt
 * @returns {T | Error} External JWT Payload Data
 */
export function verifyExternalJWTToken<T>(jwt: string): T {
  // Check if is a valid token
  const isValid = KJUR.jws.JWS.verify(jwt, SECRET, [ALG]);

  // Throw error if is not valid
  if (!isValid) throw new Error('invalid-token');

  // Get header
  const header = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(jwt.split('.')[0]));

  // Get payload
  const payload: ExternalJWTPayload<T> = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(jwt.split('.')[1]));

  // Get current time
  const current = KJUR.jws.IntDate.get('now');

  // Invalid
  if (current < payload.nbf) {
    throw new Error('not-before');
  }

  // Expired
  if (current > payload.exp) {
    throw new Error('expired-token');
  }

  // Return payload data
  return payload.data;
};
