import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '@core/services';
import { DataPickerDialogFilter } from '@shared/models';

type Data = {
  filterData: DataPickerDialogFilter;
  cleanEvent: () => void;
  ignoreTzTreatment?: boolean;
  returnTimestamp?: boolean;
};

@Component({
  selector: 'app-dialog-datepicker',
  templateUrl: './dialog-datepicker.component.html',
  styleUrls: ['./dialog-datepicker.component.scss']
})
export class DialogDatepickerComponent implements OnInit {
  public form: FormGroup = this.fb.group<DataPickerDialogFilter>({
    initialDate: '',
    finalDate: ''
  });

  constructor(
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogDatepickerComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.fillFields(this.data.filterData);
  }

  /**
   * Fill in the form fields
   */
  private fillFields(filterData: any): void {
    // Set initial date
    if (filterData?.initialDate) {
      const initDate = this.data.ignoreTzTreatment
        ? new Date(filterData.initialDate)
        : new Date(this.utilsService.getDateInTimeZone(filterData.initialDate))

      // Set form values
      this.form.get('initialDate').setValue(initDate)
    };

    // Set final date
    if (filterData?.finalDate) {
      const finalDate = this.data.ignoreTzTreatment
        ? new Date(filterData.finalDate)
        : new Date(this.utilsService.getDateInTimeZone(filterData.finalDate))

      // Set form values
      this.form.get('finalDate').setValue(finalDate)
    };
  }

  /**
   * Close dialog and reset filter
   */
  public cleanFilter(): void {
    this.data.cleanEvent();
    this.dialogRef.close(null)
  }

  /**
   * Close the dialog and send the values
   */
  public save(): void {
    // Get form values
    const data = this.form.value;

    // Get initial date without timezone
    const initial = !this.data.ignoreTzTreatment
      ? this.utilsService.getDateWithoutTimeZone(data.initialDate)
      : this.utilsService.getFormattedDate(data.initialDate);

    // Get final date without timezone
    const final = !this.data.ignoreTzTreatment
      ? this.utilsService.getDateWithoutTimeZone(data.finalDate)
      : this.utilsService.getFormattedDate(data.finalDate);

    // Close dialog and send values
    this.dialogRef.close({
      initialDate: this.data.returnTimestamp ? new Date(initial).getTime() : initial,
      finalDate: this.data.returnTimestamp ? new Date(final).getTime() : final
    })
  }

  /**
   * Verify if form is empty
   * @returns {boolean} false if form is not empty
   */
  public isFormEmpty(): boolean {
    return !Object.keys(this.form.value).some((k) => this.form.value[k])
  }
}
