export enum MatrixRoomType {
  MEET = 'JitsiMeet',
  GPT = 'ChatGPT',
  NORMAL = 'Normal',
}

export type ChatRoomType = 'dm' | 'group' | 'chatgpt';

export enum RoomTabs {
  CHAT = 'chat',
  FILES = 'files',
}

export enum RoomFileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  OTHER = 'other',
  MEDIA = 'media',
}

export enum FileTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  OTHER = 'other',
}

export enum MatrixClientStatus {
  START = 'start',
  STOP = 'stop',
}

export enum CallEventTypes {
  INVITE = 'Invite',
  HANGUP = 'Hangup',
}

export enum MatrixNotificationType {
  DEFAULT = 'default',
  ALL_MESSAGES = 'all_messages',
  MENTIONS_AND_KEYWORDS = 'mentions_and_keywords',
  MUTE = 'mute',
}

export enum MatrixSyncStatus {
  NULL = 'NULL',
  SYNCING = 'SYNCING',
  PREPARED = 'PREPARED',
  RECONNECTING = 'RECONNECTING',
  CATCHUP = 'CATCHUP',
  ERROR = 'ERROR',
  STOPPED = 'STOPPED',
}

export enum MatrixRoomTimeline {
  READY = 'READY',
  EVENT = 'EVENT',
  PAGINATED = 'PAGINATED',
  TYPING_MEMBERS_UPDATED = 'TYPING_MEMBERS_UPDATED',
  LIVE_RECEIPT = 'LIVE_RECEIPT',
  EVENT_REDACTED = 'EVENT_REDACTED',
  AT_BOTTOM = 'AT_BOTTOM',
  SCROLL_TO_LIVE = 'SCROLL_TO_LIVE',
};

export enum MatrixMemberActions {
  leave = 'leave',
  join = 'join',
  call = 'call',
  invite = 'invite',
  name = 'name',
  avatar = 'avatar',
  power_levels = 'power_levels',
};
